import * as React from "react";
import { Container, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { Link } from "gatsby";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';


const EdgeqPressKit3 = () => (
  <Layout>
    <Seo title="EdgeQ | Edgeq Press Kit" />


    <section className="News-detail">
        <Container>

                <Row>

                  <div className="detail-head">
                   <Link to="/media/#August" className="back-btn"><FiArrowLeft/> Company Announcements</Link>

                  <h2>EdgeQ Samples World’s First Software Defined 5G Base Station-on-a-Chip to Market Leaders in Enterprise, Telco and Hyperscale Cloud</h2>
                  </div>

                  <div className="detail-para">
                    
              <p className="text-left"><i>EdgeQ Drastically Reduces Total Cost of Ownership with Its Revolutionary Software-Defined 5G + AI Edge Platform</i></p>

              <div className="ajit-social">
                <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>
            <p><b>SANTA CLARA, CA</b> – August 18, 2021 – <Link to="/">EdgeQ Inc</Link>, a leader in 5G wireless infrastructure, today announced sampling of its revolutionary 5G Base Station-on-a-Chip to Tier 1 customers deploying next-generation wireless edge networks for the enterprise, telco and cloud markets. EdgeQ is sampling the full suite solution to customers developing enterprise-grade 5G access points, Open-Radio Access Network (O-RAN) based Radio Unit (RU) and Distributed Unit (DU).</p>

            <p>Successful deployment of Radio Access Networks (RAN) predicates on the availability and access to a production-ready Physical Layer (PHY) software that governs all the essential protocols and features of 4G/5G. Traditional merchant silicon vendors offer the PHY as a reference software, placing the development burden on customers to invest years of effort to operationalize into production. By abstracting this friction with a total platform solution including a production readied 5G PHY software, EdgeQ frees customers from the substantial investments, resources and time typically associated with productizing the 4G/5G PHY stack.</p>

            <p>“Traditional market approaches offer baseband processing hardware and PHY software as separate topics, where the burden of proof rests on customers to fully productize and enable. EdgeQ has the ability and completeness to accelerate the market with a production-grade RAN stack that is also customizable and programmable,” said Chris DePuy, Technology Analyst at 650 Group. “The linchpin to lowering the 5G entry barrier is enabling operators and organizations with a total turnkey solution that is at the same time agile, flexible, and complete.”</p>

            <p>Three years in the making, EdgeQ has been collaborating with market-leading wireless infrastructure customers to architect a highly optimized 5G baseband, networking, compute and AI inference systemon-a-chip. By coupling a highly integrated silicon with a production-ready 5G PHY software, EdgeQ uniquely enables a frictionless operating model where customers can deploy all key functionalities and critical algorithms of the radio access network such as beamforming, channel estimation, massive MIMO and interference cancellation out of the box.</p>

            <p>For customers looking to engineer value-adds into their 5G RAN designs, the EdgeQ PHY layer is completely programmable and extensible. Customers can leverage an extendable nFAPI interface to add their custom extensions for 5G services to target the broad variety of 5G applications spanning Industry 4.0 to campus networks and fixed wireless to telco-grade macro cells. As an industry first, the EdgeQ 5G platform holistically addresses the pain point of deploying 5G PHY and MAC software layers, but with an open framework that enables a rich ecosystem of L2/L3 software partners.</p>
            
            <p>“Since day one, EdgeQ has been relentless about redefining the consumption and deployment model of 5G with its RISC-V based open architecture that converges connectivity, networking, and compute. How we elegantly club the hardware, the deployable RAN software, and an innovative chipset-as-a-service business model all together is what crystallizes the vision in a disruptively compelling way,” said Vinay Ravuri, CEO and Founder, EdgeQ. “Our sampling announcement today signifies that all this is a market reality.”</p>

            
              <b>About EdgeQ</b>
              <br />
              <br />
              <p>EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering a converged connectivity and AI that is fully software-customizable and programmable. The company is backed by leading investors, including Threshold Ventures, Fusion Fund and AME Cloud Ventures, among others. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link></p>

</div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default EdgeqPressKit3;
